import { Box } from '@ghq-abi/design-system';

import { DesktopMenu, ErrorBoundary, Header } from '~/app/components';
import { useFullscreen } from '~/app/contexts/FullscreenContext';
import { useLogout } from '~/shared/auth/useLogout';
import { useStellarWidget } from '~/shared/hooks/useStellarWidget';
import { useResponsive } from '~/shared/utils/media';

import { StyledContent, StyledWrapper } from './styles';

type AppTemplateProps = {
  children: React.ReactNode;
};

export function AppTemplate({ children }: AppTemplateProps) {
  useLogout();

  useStellarWidget();

  const fullscreen = useFullscreen();
  const { isDesktop } = useResponsive();

  return (
    <>
      {fullscreen.deactivated && <Header />}

      <StyledWrapper fullscreen={fullscreen.activated}>
        {fullscreen.deactivated && isDesktop && <DesktopMenu />}

        <StyledContent as="main" fullscreen={fullscreen.activated}>
          <ErrorBoundary inApp>
            <Box css={{ flex: 1 }}>{children}</Box>
          </ErrorBoundary>
        </StyledContent>
      </StyledWrapper>
    </>
  );
}
