import { Flex } from '@ghq-abi/design-system';

import { StatusBadge } from '~/entities/ManagerApproval/ui/elements/StatusBadge';

type ProfileBadgeProps = {
  color: string;
  status?: string;
};
export function ProfileBadge({ color, status }: ProfileBadgeProps) {
  return (
    <Flex align="start">
      <StatusBadge color={color} status={status} />
    </Flex>
  );
}
