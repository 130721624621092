import * as React from 'react';
import { IconButtonV2 } from '@ghq-abi/design-system';
import {
  FilterCircleFillIcon,
  FilterCircleIcon,
} from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';

type FilterButtonProps = {
  isShowingFilters: boolean;
  disabled: boolean;
  onClick: () => void;
};

export function FilterButton({
  isShowingFilters,
  disabled,
  onClick,
}: FilterButtonProps) {
  const { t } = useTranslate('default');

  return (
    <IconButtonV2
      type="button"
      variant="secondary"
      size="md"
      title={`${isShowingFilters ? t('common.hide') : t('common.show')} ${t(
        'common.filters'
      )}`}
      icon={isShowingFilters ? <FilterCircleFillIcon /> : <FilterCircleIcon />}
      onClick={onClick}
      disabled={disabled}
    />
  );
}
