import { useCallback, useMemo } from 'react';
import {
  Calendar3Icon,
  Clipboard1CheckIcon,
  DownloadIcon,
  GearIcon,
  HouseIcon,
  PeopleIcon,
  PersonGearIcon,
  VectorIcon,
} from '@ghq-abi/design-system-icons';
import { useTranslate } from '@tolgee/react';

import { useAbility } from '~/app/contexts/AbilityContext';
import { useFlags } from '~/app/contexts/FlagsContext';
import { RouteObject } from '~/app/types';
import { PagesPathsKeys } from '~/shared/constants/pages';

export function useMenuRoutes() {
  const { t } = useTranslate();
  const ability = useAbility();

  const flags = useFlags();

  const hasAuthorizedFlags = useCallback(
    (authorizedFlags: PagesPathsKeys | PagesPathsKeys[]) => {
      if (
        typeof authorizedFlags === 'string' &&
        !flags[authorizedFlags].active
      ) {
        return false;
      }

      if (
        Array.isArray(authorizedFlags) &&
        !authorizedFlags.some(flag => flags[flag].active)
      ) {
        return false;
      }

      return true;
    },
    [flags]
  );

  const { menuRoutes, footerRoutes } = useMemo(() => {
    const menuRoutesList: RouteObject[] = [];
    const footerRoutesList: RouteObject[] = [];

    if (hasAuthorizedFlags('home') && ability.can('view', 'Home')) {
      menuRoutesList.push({
        key: 'home',
        icon: <HouseIcon />,
        path: '/home',
        title: t('menu.home'),
      });
    }

    if (
      hasAuthorizedFlags('nominations') &&
      ability.can('view', 'Nominations')
    ) {
      menuRoutesList.push({
        key: 'nominations',
        icon: <PeopleIcon />,
        path: '/nominations',
        title: t('menu.nominations'),
      });
    }

    if (
      hasAuthorizedFlags('managerApproval') &&
      ability.can('view', 'ManagerApproval')
    ) {
      menuRoutesList.push({
        key: 'managerApproval',
        icon: <VectorIcon />,
        path: '/manager-approval',
        title: t('menu.manager_approval'),
      });
    }

    if (
      hasAuthorizedFlags('evaluations') &&
      ability.can('view', 'Evaluations')
    ) {
      menuRoutesList.push({
        key: 'evaluations',
        icon: <Clipboard1CheckIcon />,
        path: '/evaluations',
        title: t('menu.evaluations'),
      });
    }

    if (hasAuthorizedFlags('reports') && ability.can('view', 'Reports')) {
      menuRoutesList.push({
        key: 'reports',
        icon: <Calendar3Icon />,
        path: '/reports',
        title: t('menu.reports'),
      });
    }

    if (hasAuthorizedFlags('admin') && ability.can('view', 'Admin')) {
      menuRoutesList.push({
        key: 'admin',
        icon: <PersonGearIcon />,
        path: '/admin',
        title: t('menu.admin'),
      });
    }

    if (
      hasAuthorizedFlags('downloadDocuments') &&
      ability.can('view', 'DownloadDocuments')
    ) {
      menuRoutesList.push({
        key: 'downloadDocuments',
        icon: <DownloadIcon color="#191F2E" />,
        path: '/download-documents',
        title: t('menu.download_documents'),
      });
    }

    if (
      hasAuthorizedFlags(['roleSetting']) &&
      ability.can('manage', 'RoleSetting')
    ) {
      menuRoutesList.push({
        key: 'settings',
        title: t('menu.admin_settings'),
        icon: <GearIcon color="#191F2E" />,
        path: '/role-setting',
        routeComponents: [
          {
            key: 'roleSetting',
            path: '/role-setting',
            title: t('menu.role_setting.title'),
          },
        ],
      });
    }

    return { menuRoutes: menuRoutesList, footerRoutes: footerRoutesList };
  }, [ability, hasAuthorizedFlags, t]);

  return { menuRoutes, footerRoutes };
}
