import { Flex, Text } from '@ghq-abi/design-system';

import { StatusCircle } from '../styles';

type StatusBadgeProps = {
  status?: string;
  color?: string;
};
export function StatusBadge({ color, status }: StatusBadgeProps) {
  return (
    <Flex align="center" gap="xs">
      <StatusCircle
        css={{
          bg: color,
        }}
      />

      <Text>{status}</Text>
    </Flex>
  );
}
