import * as React from 'react';
import { useTimeout } from 'react-use';
import { useRouter } from 'next/router';
import { signIn, signOut } from 'next-auth/react';
import {
  Dropdown,
  Flex,
  MediaMatch,
  Text,
  useResponsiveBreakpoint,
} from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import nprogress from 'nprogress';

import { useAuth } from '~/app/contexts/AuthContext';
import { useSessionInfo } from '~/app/contexts/SessionInfoContext';
import type { User } from '~/shared/auth/useUser';
import { Avatar, Can, ClientOnly, Flags, Icon } from '~/shared/components';
import { SignOut } from '~/shared/components/icons/SignOut';

import {
  StyledArrow,
  StyledAvatarItem,
  StyledBox,
  StyledContent,
  StyledContext,
  StyledDropdownItem,
} from './styles';

type HeaderProps = { user: User };

export function UserDropdown({ user }: HeaderProps) {
  const { t } = useTranslate(['default']);
  const { sessionInfo: session } = useSessionInfo();
  const router = useRouter();
  const { notifyChangedProxy } = useAuth();

  // * This is necessary for force the component re-render in order to apply the correct button width
  useTimeout(500);
  useResponsiveBreakpoint();

  const userNames = user.name?.split(' ') ?? '';
  const simplifiedName = `${userNames.at(0)} ${userNames.at(-1)}`;

  const dropdownButtonRef = React.useRef<HTMLButtonElement>(null);

  const isProxying = !!session?.user?.proxiedAs;

  async function handleSignOut() {
    try {
      await fetch('/api/destroy-cookies');
    } finally {
      signOut({ redirect: false });
    }
  }

  async function handleStopProxy() {
    if (!session) {
      return;
    }

    nprogress.start();
    await signIn('register-proxy', {
      user: JSON.stringify({ ...session.user, proxiedAs: null }),
    });
    notifyChangedProxy();
  }

  return (
    <Dropdown>
      <Dropdown.Trigger asChild>
        <StyledContext ref={dropdownButtonRef}>
          <Flex as="span" align="center" css={{ gap: '$3' }}>
            <ClientOnly placeholder={<StyledBox />}>
              <Avatar name={user.name ?? ''} globalId={user.globalId ?? ''} />
            </ClientOnly>
          </Flex>

          <StyledArrow />
        </StyledContext>
      </Dropdown.Trigger>
      <StyledContent align="end">
        <StyledAvatarItem onSelect={handleSignOut}>
          <Avatar
            size="md"
            name={user.name ?? ''}
            globalId={user.globalId ?? ''}
          />

          <StyledBox>
            <MediaMatch greaterThan="md">
              <Text
                css={{
                  color: '#191F2E',
                  fontSize: '$2',
                  fontWeight: '$medium',
                }}
              >
                <Text
                  as="span"
                  css={{ display: 'none', '@lg': { display: 'block' } }}
                >
                  {user.name}
                </Text>
                <Text
                  as="span"
                  css={{ display: 'block', '@lg': { display: 'none' } }}
                >
                  {simplifiedName}
                </Text>
              </Text>
              {user.zone ? (
                <Text
                  css={{
                    color: '#191F2E',
                    fontSize: '$1',
                    fontWeight: '$normal',
                  }}
                >
                  {user.zone}
                </Text>
              ) : null}
            </MediaMatch>
          </StyledBox>
        </StyledAvatarItem>
        {isProxying ? (
          <StyledDropdownItem onSelect={handleStopProxy}>
            <Icon.StopProxy size={18} />
            <Text as="span" css={{ flex: 1 }}>
              {t('common.stop_proxy')}
            </Text>
          </StyledDropdownItem>
        ) : (
          <Can I="proxy" an="User">
            <Flags authorizedFlags="proxy">
              <StyledDropdownItem onSelect={() => router.push('/proxy')}>
                <Icon.Proxy size={18} />
                <Text as="span" css={{ flex: 1 }}>
                  {t('common.proxy_as_user')}
                </Text>
              </StyledDropdownItem>
            </Flags>
          </Can>
        )}
        <StyledDropdownItem onSelect={handleSignOut}>
          <SignOut size={16} />
          <Text as="span" css={{ flex: 1 }}>
            {t('common.sign_out')}
          </Text>
        </StyledDropdownItem>
      </StyledContent>
    </Dropdown>
  );
}
