import { useMemo, useState } from 'react';

import { useResponsive } from '../utils/media';

export function useClickableTooltip() {
  const { isMobile } = useResponsive();

  const [isOpen, setIsOpen] = useState(false);

  const openTooltip = () => {
    setIsOpen(true);
  };

  const closeTooltip = () => {
    setIsOpen(false);
  };

  const onRootTooltipChange = (open: boolean) => {
    if (!open) setIsOpen(false);
  };

  const rootTooltipProps = useMemo(
    () => (isMobile ? { open: isOpen, onOpenChange: onRootTooltipChange } : {}),
    [isMobile, isOpen]
  );

  return {
    isOpen,
    rootTooltipProps,
    openTooltip,
    closeTooltip,
  };
}
