import { keyframes, styled } from '@ghq-abi/design-system';

import { MAX_CIRCLE_SIZE } from './constants';

export const Container = styled('div', {
  height: '120px',
  width: '120px',
  borderRadius: '$circle',
  position: 'relative',
});

export const Outer = styled('div', {
  height: '100%',
  width: '100%',
  borderRadius: '$circle',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '4px solid $gray450',
});

export const slideForward = (progress: number) =>
  keyframes({
    from: {
      strokeDashoffset: MAX_CIRCLE_SIZE,
    },
    to: {
      strokeDashoffset: progress,
    },
  });

export const Circle = styled('circle', {
  fill: 'none',
  stroke: 'url(#gradient-color)',
  strokeWidth: '4px',
  strokeDasharray: MAX_CIRCLE_SIZE,
});

export const CustomSvg = styled('svg', {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
});
