import { styled } from '@ghq-abi/design-system';

export const ContentBox = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$white',
  border: '1px solid #E2E4E8',
  boxShadow: '0px 4px 8px 0px #60617029',
  borderRadius: '$2',
  p: '$md',
  width: '$full',
});
