// ! All custom icons should have color and size props
export * from './Admin';
export * from './ApproveAndReject';
export * from './Approved';
export * from './ArrowCurved';
export * from './BackArrowIcon';
export * from './Badge';
export * from './CheckedTask';
export * from './Cheershub';
export * from './CheersHubIcon';
export * from './Chevron';
export * from './CircleCheck';
export * from './CircularNextArrow';
export * from './Decline';
export * from './DownloadDocuments';
export * from './DownloadPdf';
export * from './EditBox';
export * from './Excel';
export * from './Feedback';
export * from './Filter';
export * from './FilterCircle';
export * from './Home';
export * from './InfoCircleFilled';
export * from './LcmIcon';
export * from './MenuArrowIcon';
export * from './MoneyBag';
export * from './Nominations';
export * from './OtherAppsIcon';
export * from './OurAppsIcon';
export * from './Overview';
export * from './Pdf';
export * from './PdfFile';
export * from './Proxy';
export * from './Reports';
export * from './Save';
export * from './StartProxy';
export * from './StopProxy';
export * from './SubmitDocument';
export * from './Survey';
export * from './Target';
export * from './Trash';
export * from './UncheckedTask';
export * from './Undo';
