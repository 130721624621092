import React from 'react';
import { Button, Dropdown, reusables, Skeleton } from '@ghq-abi/design-system';
import { TranslateIcon } from '@ghq-abi/design-system-icons';

import { useLanguageDropdown } from './hooks';

type LanguageDropdownProps = {
  currentLanguage?: string;
};

export const LanguageDropdown = ({
  currentLanguage,
}: LanguageDropdownProps) => {
  const {
    availableLanguages,
    selectedLanguageLabel,
    isLoadingLanguages,
    handleLanguageChange,
  } = useLanguageDropdown(currentLanguage);

  if (isLoadingLanguages) {
    return <Skeleton variant="rectangle" height={46} width={124} />;
  }

  return (
    <Dropdown>
      <Dropdown.Trigger asChild disabled={false}>
        <Button
          css={{ boxShadow: 'none' }}
          variant="secondary"
          rightIcon={<TranslateIcon width={16} />}
        >
          {selectedLanguageLabel}
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content
        className={reusables.scrollbar()}
        css={{
          height: '$md',
          overflow: 'auto',
          zIndex: '$overlay',
          boxShadow: '8px 0px 16px 0px #191F2E3D',
        }}
      >
        {availableLanguages?.map(lang => (
          <Dropdown.Item
            onSelect={() => handleLanguageChange(lang)}
            key={lang.id}
          >
            {lang.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown>
  );
};
