import { TbArrowDown } from 'react-icons/tb';
import { CSS, styled } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

type OrderButtonProps = {
  children: string;
  as?: 'button' | 'span';
  direction?: 'asc' | 'desc';
  disabled?: boolean;
  full?: boolean;
  onClick?: () => void;
  css?: CSS;
};

const StyledButton = styled('button', {
  appearance: 'none',
  border: 0,
  backgroundColor: 'transparent',
  color: 'inherit',
  fontWeight: 'inherit',
  cursor: 'pointer',
  transition: '$promptly-ease',
  whiteSpace: 'pre',

  display: 'flex',
  alignItems: 'center',
  gap: '$sm',
  justifyContent: 'center',

  '&:disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },

  svg: {
    transition: '$quickly-ease',
    transform: 'rotate(180deg)',
  },

  variants: {
    full: {
      true: {
        width: '$full',
      },
    },
    rotateIcon: {
      true: {
        svg: {
          transform: 'rotate(0deg)',
        },
      },
    },
    text: {
      true: {
        cursor: 'default',
      },
    },
  },
});

export function OrderButton({
  children,
  as = 'button',
  direction = 'asc',
  disabled = false,
  full = false,
  css,
  onClick,
}: OrderButtonProps) {
  const { t } = useTranslate('default');

  return (
    <StyledButton
      as={as}
      type={as === 'button' ? 'button' : undefined}
      disabled={as === 'button' ? disabled : undefined}
      rotateIcon={direction === 'desc'}
      full={full}
      title={
        as === 'button'
          ? t('common.order_by', {
              field: children,
              direction: t(
                `common.${direction === 'asc' ? 'descending' : 'ascending'}`
              ).toLowerCase(),
            })
          : undefined
      }
      onClick={as === 'button' ? onClick : undefined}
      css={css}
      text={as === 'span'}
    >
      {children}
      {!disabled && as === 'button' && (
        <TbArrowDown size={16} style={{ flexShrink: 0 }} />
      )}
    </StyledButton>
  );
}
