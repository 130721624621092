export const NOMINATION_STATUS_CONFIG_STYLE = {
  NOT_SUBMITTED: {
    label: 'not_submitted',
    color: '$gray450',
  },
  PENDING_MANAGERS_APPROVAL: {
    label: 'pending_managers_approval',
    color: '#FFF3A1',
  },
  SUBMITTED: {
    label: 'submitted',
    color: '#20C9AC',
  },
  FEEDBACK_GIVEN: {
    label: 'feedback_given',
    color: '#ED5F00',
  },
};
