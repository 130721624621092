import { AssessmentType } from './AssessmentType';
import { Band } from './Band';
import { ContentRoot } from './ContentRoot';
import { FirstAndLastName } from './FirstAndLastName';
import { FullName } from './FullName';
import { GlobalId } from './GlobalId';
import { Persona } from './Persona';
import { PositionAndZone } from './PositionAndZone';
import { RaterRelationship } from './RaterRelationship';

export const Content = {
  Root: ContentRoot,
  FullName,
  FirstAndLastName,
  PositionAndZone,
  AssessmentType,
  RaterRelationship,
  Band,
  GlobalId,
  Persona,
};
