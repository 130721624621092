import { Box, styled } from '@ghq-abi/design-system';

export const EmployeeCardContainer = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '$default',
  gap: '$sm',
  p: '$sm',
  cursor: 'pointer',
  border: 0,
  bg: '$gray300',
  textAlign: 'left',
  transition: '$quickly-ease',
  '&:hover': { filter: 'brightness(0.92 )' },
  '&:active': { filter: 'brightness(0.92)' },

  variants: {
    active: {
      true: {
        filter: 'brightness(0.90)',
      },
    },
    surveyStatus: {
      PENDING_MANAGERS_APPROVAL: {
        bg: '#FFFCE8',
      },
      SUBMITTED: {
        bg: '#EEFFF4',
      },
      FEEDBACK_GIVEN: {
        bg: '#F7CFB4',
      },
      NOT_SUBMITTED: {
        bg: '$gray300',
      },
    },
  },
});

export const StatusCircle = styled('div', {
  borderRadius: '$circle',
  width: '$2-5',
  height: '$2-5',
});

export const NominationDetailsContainer = styled(Box, {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '$full',
  overflowY: 'auto',
  p: '$px',
  pr: '$sm',
});
