import { ButtonV2, Dialog, Flex, reusables } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

interface ConfirmationDialogProps {
  title: string;
  description: string;
  additionalContent?: React.ReactNode;
  isOpen?: boolean;
  isLoading?: boolean;
  confirmLabel: string;
  confirmLeftIcon?: React.ReactNode;
  cancelLabel: string;
  isCancelActionInProgress?: boolean;
  onClose: () => void;
  onConfirmation: () => void;
}
export function ConfirmationDialog({
  isLoading,
  description,
  title,
  isOpen,
  confirmLabel,
  cancelLabel,
  additionalContent,
  confirmLeftIcon,
  isCancelActionInProgress,
  onClose,
  onConfirmation,
}: ConfirmationDialogProps) {
  const { t } = useTranslate('default');

  return (
    <Dialog open={isOpen}>
      <Dialog.Content
        css={{
          overflow: 'auto',
          maxWidth: 'calc($lg + $7)',
        }}
        className={reusables.scrollbar()}
      >
        <Dialog.Close asChild>
          <Dialog.CloseIcon
            aria-label={t('common.close_dialog')}
            onClick={onClose}
            disabled={isLoading || isCancelActionInProgress}
          />
        </Dialog.Close>

        <Dialog.Title
          css={{ textAlign: 'center', color: '$gray780', fontWeight: '$bold' }}
        >
          {title}
        </Dialog.Title>

        <Dialog.Description css={{ textAlign: 'center', color: '$gray780' }}>
          {description}
        </Dialog.Description>

        {additionalContent && additionalContent}

        <Flex justify="center" gap="lg" css={{ mt: '$10' }}>
          <ButtonV2
            size="md"
            onClick={onConfirmation}
            loading={isLoading}
            disabled={isLoading || isCancelActionInProgress}
            leftIcon={confirmLeftIcon || null}
            css={{
              minWidth: 'calc($28 + $4-5)',
              width: 'fit-content',
            }}
            data-dd-action-name="confirm-btn"
          >
            {confirmLabel}
          </ButtonV2>

          <Dialog.Close asChild>
            <ButtonV2
              size="md"
              onClick={onClose}
              variant="secondary"
              disabled={isCancelActionInProgress || isLoading}
              loading={isCancelActionInProgress}
              css={{
                minWidth: 'calc($28 + $4-5)',
                width: 'fit-content',
              }}
              data-dd-action-name="cancel-btn"
            >
              {cancelLabel}
            </ButtonV2>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog>
  );
}
