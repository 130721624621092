import { ChangeEvent } from 'react';
import { IoMdSearch } from 'react-icons/io';
import { CSS, Flex, Label, TextField, theme } from '@ghq-abi/design-system';

type FilterTextFieldProps = {
  id: string;
  value: string;
  placeholder: string;
  label?: string;
  isLoading: boolean;
  disabled: boolean;
  css?: CSS;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
};

export function FilterTextField({
  id,
  label,
  value,
  placeholder,
  isLoading,
  disabled,
  onChange,
  css,
  name,
}: FilterTextFieldProps) {
  return (
    <Flex direction="column" gap="md" css={css}>
      {label && (
        <Label
          htmlFor={id}
          css={{
            fontWeight: '$medium',
            color: '$gray750',
            fontSize: '$sm',
          }}
        >
          {label}
        </Label>
      )}
      <TextField
        id={id}
        name={name}
        disabled={isLoading || disabled}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        rightIcon={
          <IoMdSearch
            style={{
              width: theme.sizes[6].value,
              height: theme.sizes[6].value,
            }}
          />
        }
        css={{ fontSize: '$sm', height: '$10' }}
        containerCss={{ flex: 1 }}
      />
    </Flex>
  );
}
