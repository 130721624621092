import { Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';
import { SurveyType } from 'abi-lcm-common-types';

type AssessmentTypeProps = {
  type?: SurveyType;
};

export function AssessmentType({ type }: AssessmentTypeProps) {
  const { t } = useTranslate('default');
  return (
    <Text
      css={{
        display: 'block',
        fontSize: '$xs',
        color: '$gray575',
        fontWeight: '$normal',
      }}
    >
      {`${t('common.assessment_type')}: `}
      <Text as="span" css={{ fontWeight: 'bold', fontSize: '$xs' }}>
        {type}
      </Text>
    </Text>
  );
}
